.content {
  min-height: 100vh;
}

:global(.darkTheme) .heightBar {
  display: none;
}

.header {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  padding: 20px 0px;
  transition: background-color 0.8s;
  border-bottom: 1px solid var(--header-border);
  z-index: 99;
}

.campaign.header,
.register.header {
  background-color: var(--layout-bg);
}

:global(.articlePage) .header,
.header.fixedHeader,
:global(.fixedHeader) {
  background-color: var(--layout-bg);
}

.headerLoader {
  position: relative;
  display: flex;
  align-items: center;
  gap: 64px;
}

.headerLoader .logo {
  position: relative;
  width: 120px;
  height: 50px;
}

.headerLoader .menus {
  display: flex;
  align-items: center;
  gap: 16px;
  padding-left: 220px;
}

.headerLoader .menus>div {
  width: 160px;
  margin: 0;
}

.headerLoader .button,
.headerLoader .profile {
  position: absolute;
  right: 0;
  top: -20px;
}

.headerLoader .profile {
  top: -6px;
}

.headerLoader .profile>div {
  width: 40px;
  height: 40px;
  border-radius: 50px;
}

.headerLoader .button {
  width: 220px;
  height: 50px;
}

.headerLoader .button>div {
  border-radius: 50px;
}

.floatingHeader {
  border-bottom-width: 0px;
  background: linear-gradient(180deg, #121212 0%, rgba(18, 18, 18, 0) 100%);
  z-index: 9;
  position: absolute;
}

.floatingHeader+div {
  display: none;
}

:global(.black-header) .header {
  background-color: var(--layout-bg);
}

.headerCenter {
  position: relative;
}

.headerMenu {
  position: absolute;
  top: 50%;
  padding-left: 220px;
  transform: translateY(-50%);
}

.headerMenu .linkItem {
  position: relative;
  display: inline-block;
  margin-right: 16px;
  padding: 30px 0;
}

.headerMenu .linkItem .chevronArrow {
  display: none;
}

.headerMenu .link {
  font-size: 16px;
  padding-bottom: 4px;
  margin-left: 15px;
  border-bottom: 2px solid transparent;
}

.headerMenu .link:first-child {
  margin-left: 0;
}

.headerMenu .link:hover {
  border-bottom-color: var(--layout-text);
}

.headerMenu .link.active {
  font-weight: 700;
  border-bottom-color: var(--layout-text);
}

.submenu {
  position: absolute;
  left: -15px;
  top: 75px;
  padding: 15px;
  opacity: 0;
  visibility: hidden;
  transition: opacity 0.4s, visibility 0.4s;
  min-width: 200px;
  font-size: 14px;
  font-weight: 400;
  line-height: 1.2;
  background-color: var(--section-border);
}

.header .linkItem:hover .submenu {
  opacity: 1;
  visibility: visible;
}

.submenu .submenuItem {
  padding-top: 10px;
  display: block;
}

.submenu .submenuItem:first-child {
  padding-top: 0;
}

.submenu .submenuItem span {
  border-bottom: 2px solid transparent;
  transition: border 0.4s;
  padding-bottom: 5px;
  display: inline-block;
}

.submenu .submenuItem:hover span,
.submenu .submenuItem.active span {
  border-bottom-color: var(--layout-text);
}

.logo {
  display: inline-block;
  vertical-align: middle;
  min-width: 200px;
}

.logo img {
  height: 35px;
  width: auto;
}

.signInButton {
  float: right;
  display: flex;
  gap: 16px;
}

.footer {
  padding: 85px 0px;
  border-top: 1px solid var(--footer-border);
}

.campaignPage.footer {
  background-color: var(--grey);
  border-top: 1px solid var(--layout-text);
}

.footer>div {
  position: relative;
}

.footerCenter {
  max-width: 1100px;
  margin: auto;
}

.footer .logo img {
  height: 40px;
}

.footerLeft {
  width: 55%;
  max-width: 600px;
  float: left;
  padding-right: 8%;
}

.footerRight {
  overflow: hidden;
  display: flex;
  justify-content: space-between;
}

.footerRight.block {
  display: block;
}

.supportImage {
  width: 200px;
}

.footerCol {
  display: inline-block;
}

.footerTitle {
  font-size: 16px;
  font-weight: 700;
  padding-bottom: 10px;
  text-transform: uppercase;
  line-height: 1.2;
}

.footerLinkItem {
  margin-top: 12px;
  font-size: 16px;
  text-transform: capitalize;
}

.footerLinkItem:hover {
  color: var(--link-hover);
}

.footerText {
  position: relative;
}

.footerText img {
  position: absolute;
  width: 100%;
  height: 100%;
  left: -18px;
  opacity: var(--chart-opacity);
  transition: opacity 0.4s;
}

.footerText p {
  font-family: "Cooper Hewitt";
  font-size: 15px;
  padding: 17px 0 18px 0;
  max-width: 322px;
  margin: 23px 0 60px;
  position: relative;
  line-height: 1.4;
  letter-spacing: 0.1em;
}

.socials {
  margin: 0 -15px;
}

.campaignPage .socials {
  margin: 0;
  display: flex;
  align-items: center;
}

.campaignPage .socialItem {
  margin: 0;
}

.campaignPage .socialItem+.socialItem {
  padding-left: 24px;
}

.campaign .hamburgerMenu {
  display: none;
}

.register .search {
  display: none;
}

.socialItem {
  margin: 10px 15px 0;
  display: inline-block;
  font-size: 24px;
}

.companyRegistration {
  margin-top: 60px;
  font-size: 10px;
}

.profileSection>* {
  display: inline-block;
  vertical-align: middle;
  margin-left: 20px;
}

.profileSection .icon {
  position: relative;
  padding: 0;
  width: 32px;
  height: 32px;
  border-radius: 50%;
  overflow: hidden;
}

.search svg {
  width: 18px;
  height: 18px;
}

.search:hover {
  color: var(--link-hover);
}

.profileSection .letter {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  width: 100%;
  background-color: var(--button-bg);
  color: var(--button-text);
  font-weight: 600;
  font-family: "Cooper Hewitt", sans-serif;
  text-transform: uppercase;
}

:global(.profile) .profileSection .icon {
  border: 2px solid var(--layout-text);
}

.content :global(.section):last-child {
  padding-bottom: 60px;
}

.hamburgerMenu {
  display: none;
}

.authorize {
  position: relative;
  border-top: 1px solid var(--footer-border);
  display: flex;
  align-items: center;
  justify-content: center;
}

.authorize .container {
  display: grid;
  gap: 10px;
  background-color: var(--button-bg);
  padding: 50px 90px;
  border-radius: 18px;
  color: #000;
  min-width: 520px;
  z-index: 2;
  text-align: center;
}

.authorize .spinner {
  position: relative;
  height: 20px;
}

.authContainer {
  color: #fff;
  background-color: #000;
  text-align: center;
  margin: auto;
}

.authContainer .authContent {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.authContent .spinner {
  position: relative;
  height: 50px;
}

/* Mobile Hambuger menu begin */
.mobileMenu {
  position: fixed;
  width: 100%;
  height: 100%;
  background-color: var(--section-bg);
  overflow: auto;
  z-index: 9998;
  transform: translateY(100%);
  transition: transform 0.8s;
}

.mobileMenu.show {
  transform: translateY(0);
}

.mobileMenu .container {
  position: absolute;
  color: var(--white-bordered-btn-color);
  height: 100%;
  top: 0;
  left: 0;
  width: 100%;
  padding: 0 30px;
}

.mobileMenu .closeBtn {
  position: fixed;
  padding: 30px 26px;
}

.mobileMenu .closeBtn svg {
  width: 18px;
  height: 18px;
}

.mobileMenu .items {
  position: relative;
  display: grid;
  gap: 32px;
  margin-top: 24px;
  font-size: 20px;
  font-family: "Cooper Hewitt";
  line-height: 1.2;
}

.mobileMenu .items>a {
  padding-bottom: 28px;
  border-bottom: 1px solid var(--link-border);
}

.mobileMenu .linkItem {
  position: relative;
  padding-bottom: 28px;
  text-transform: uppercase;
  border-bottom: 1px solid var(--footer-border);
}

.mobileMenu .linkItem .link {
  display: block;
}

.mobileMenu .linkItem .chevronArrow {
  position: absolute;
  top: -5px;
  right: -10px;
  transform: rotate(270deg);
  padding: 10px;
}

.mobileMenu .linkItem .chevronArrow>div {
  width: 16px;
  height: 16px;
}

.mobileMenu .submenu .submenuItem.active span {
  border-bottom-color: transparent;
}

.mobileMenu .submenu {
  position: static;
  text-transform: none;
  max-height: 0;
  transition: opacity 0.4s 0s, visibility 0.4s 0s, max-height 0.4s 0s;
  padding-left: 0;
  padding-right: 0;
}

.mobileMenu .showSubMenu .submenu {
  max-height: 1000px;
  opacity: 1;
  visibility: visible;
  transition: opacity 0.4s 0.4s, visibility 0.4s 0.4s max-height 0.4s 0.5s;
  margin-top: 10px;
  margin-bottom: 10px;
  padding-left: 15px;
  padding-right: 15px;
}

.mobileMenu .linkItem.hasSubmenu {
  padding-bottom: 0;
}

.mobileMenu .showSubMenu .chevronArrow {
  transform: rotate(360deg);
}

/* Mobile Hambuger menu end */

/* Welcome Popup Begin */
.welcomePopup {
  position: relative;
  min-height: 100vh;
}

.welcomePopup .text {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: var(--layout-bg);
  border-radius: 4px;
  color: var(--layout-text);
  padding: 70px;
}

.welcomePopup h4 {
  font-family: "Cooper Hewitt";
  font-size: 33px;
}

.welcomePopup p {
  margin: 16px 0 26px 0;
  font-size: 16px;
}

/* Welcome Popup Css */

/* Footer Podium logo begin */
.podiumLogo {
  position: absolute;
  right: 60px;
  bottom: 0;
}

.podiumLogo .logo {
  text-align: end;
}

.footerPodiumLogo .logo img,
.podiumLogo .logo img {
  height: 52px !important;
}

.podiumHeaderLogo img {
  height: 45px;
}
/* Footer Podium logo end */

.blackBar.header {
  background-color: #000;
}

@media (max-width: 1100px) {
  .footerLeft {
    padding-right: 50px;
    width: 50%;
  }
}

@media (min-width: 1025px) {
  .header .logo {
    position: absolute;
    left: 0;
    top: 50%;
    transform: translateY(-50%);
    z-index: 1;
  }

  .clearClass {
    clear: both;
  }
}

@media (max-width: 1024px) {
  .footerLeft {
    width: 45%;
  }

  .welcomePopup .text {
    width: 70%;
  }

  .header .logo {
    min-width: 0;
    display: flex;
  }

  .logo img {
    height: 30px;
  }

  .footerText img {
    left: -16px;
  }

  .podiumLogo {
    right: 40px;
  }

  .podiumHeaderLogo img {
    height: 40px;
  }

  .signInButton a {
    padding: 8px 15px;
    font-size: 14px;
  }

  .hamburgerMenu {
    display: inline-block;
    width: 16px;
    height: 14px;
    position: relative;
    margin: 3px 2px;
    cursor: pointer;
    cursor: pointer;
  }

  .hamburgerMenu div {
    opacity: 1;
    transition: opacity 0.4s;
  }

  .hamburgerMenu div,
  .hamburgerMenu::before,
  .hamburgerMenu::after {
    background-color: var(--layout-bg-revert);
    width: 100%;
    height: 2px;
  }

  .hamburgerMenu::before,
  .hamburgerMenu::after {
    content: "";
    position: absolute;
    top: 0;
    width: 100%;
    left: 0;
    transition-duration: 0.3s, 0.3s, 0.3s;
    /* transition-delay: .3s, 0s, 0s; */
  }

  .hamburgerMenu::after {
    top: auto;
    bottom: 0;
  }

  .hamburgerMenu.toggled:before {
    transform: rotate(225deg);
  }

  .hamburgerMenu.toggled:after {
    transform: rotate(-225deg);
  }

  .hamburgerMenu.toggled div {
    opacity: 0;
  }

  .hamburgerMenu.toggled::before,
  .hamburgerMenu.toggled::after {
    transition-delay: 0s, 0.3s;
    top: 50%;
    left: 0;
    bottom: auto;
  }

  .headerMenu {
    display: none;
    cursor: pointer;
  }

  .header .clearClass {
    display: none;
  }

  .headerCenter {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  .profileSection>* {
    margin: 0;
  }

  .profileSection .icon {
    margin-left: 8px;
  }

  .search svg {
    width: 16px;
    height: 16px;
  }

  .logo img {
    height: 25px;
  }

  .podiumHeaderLogo img {
    height: 40px;
  }
}

@media (max-width: 850px) {
  .footer {
    padding: 60px 0px;
  }

  .companyRegistration,
  .socials {
    margin-top: 40px;
  }

  .footerText p {
    margin: 30px 0 0;
  }
}

@media (max-width: 800px) {
  .footerLeft {
    width: auto;
    float: none;
    padding-right: 0;
    max-width: 450px;
  }

  .footerText p {
    max-width: 400px;
  }

  .footerRight {
    overflow: visible;
    width: 100%;
    max-width: 500px;
  }

  .footer .logo img {
    height: 40px;
  }

  .footerText p {
    padding-right: 120px;
    margin: 30px 0 40px;
  }

  .supportImage {
    margin: 30px 0 40px;
  }

  .podiumLogo {
    position: relative;
    padding: 40px 40px 0;
  }

  .podiumLogo .logo {
    text-align: start;
  }

  .signInButton a {
    font-size: 13px;
  }

  .signInButton {
    gap: 10px;
  }
  
  .podiumHeaderLogo img {
    height: 35px;
  }
}

@media (max-width: 650px) {
  .welcomePopup .text {
    width: 80%;
  }
}

@media (max-width: 550px) {
  .logo img {
    height: 25px;
  }

  .logo {
    min-width: 100px;
  }

  .footerText p {
    padding: 10px 120px 10px 0;
  }

  .supportImage {
    margin: 50px 0 40px;
  }

  .signInButton a {
    font-size: 12px;
    padding: 6px 10px;
  }

  .logo img {
    height: 20px;
  }
  
  .podiumHeaderLogo img {
    height: 30px;
  }
}

@media (max-width: 450px) {
  .footerRight {
    flex-wrap: wrap;
  }

  .footerCol {
    margin-top: 40px;
    width: 50%;
  }

  .mobileMenu .container {
    padding: 0 16px;
  }

  .footer .logo img {
    height: 36px;
  }
}

@media (max-width: 380px) {
  .footerText p {
    padding-right: 40px;
  }
}

@media (max-width: 359px) {
  .logo img {
    height: 15px;
  }
}