.ctapopup.sky {
    --inj-text: var(--dark-navy);
    --inj-back: var(--sky);
    --input-focus: var(--dark-navy);
    --secondary-color: var(--dark-navy);
    --button-bg: var(--dark-navy);
    --button-text: var(--white);
    --inj-close-color: var(--dark-navy);
    --inj-link-color: var(--dark-navy);
    --inj-close-hover: var(--white);
    --link-hover: var(--dark-navy);
    --checkbox-border: var(--dark-navy);
}

.ctapopup.yellow {
    --inj-text: var(--dark-navy);
    --inj-back: var(--yellow);
    --input-focus: var(--dark-navy);
    --secondary-color: var(--dark-navy);
    --button-bg: var(--dark-navy);
    --button-text: var(--white);
    --inj-close-color: var(--dark-navy);
    --inj-link-color: var(--dark-navy);
    --inj-close-hover: var(--orange);
    --link-hover: var(--dark-navy);
    --checkbox-border: var(--dark-navy);
}

.ctapopup.gray {
    --inj-text: var(--dark-navy);
    --inj-back: var(--grey);
    --input-focus: var(--dark-navy);
    --secondary-color: var(--dark-navy);
    --button-bg: var(--dark-navy);
    --button-text: var(--white);
    --inj-close-color: var(--dark-navy);
    --inj-link-color: var(--dark-navy);
    --inj-close-hover: var(--orange);
    --link-hover: var(--dark-navy);
    --checkbox-border: var(--dark-navy);
}

.ctapopup.orange {
    --inj-text: var(--dark-navy);
    --inj-back: var(--orange);
    --input-focus: var(--dark-navy);
    --secondary-color: var(--dark-navy);
    --button-bg: var(--dark-navy);
    --button-text: var(--white);
    --inj-close-color: var(--dark-navy);
    --inj-link-color: var(--dark-navy);
    --inj-close-hover: var(--white);
    --link-hover: var(--dark-navy);
    --checkbox-border: var(--dark-navy);
}

.ctapopup.navy {
    --inj-text: var(--white);
    --inj-back: var(--dark-navy);
    --input-focus: var(--yellow);
    --secondary-color: var(--yellow);
    --button-bg: var(--yellow);
    --button-text: var(--dark-navy);
    --inj-close-color: var(--yellow);
    --inj-link-color: var(--yellow);
    --inj-close-hover: var(--white);
    --link-hover: var(--yellow);
    --checkbox-border: var(--yellow);
}

.ctapopup {
    position: fixed;
    bottom: 0;
    left: 0;
    width: 100%;
    z-index: 9999;
    transition: transform 0.4s;
    transform: translateY(100%);
    background-color: var(--inj-back);
    color: var(--inj-text);
}

.ctapopup > .wrap {
    padding: 60px 120px 60px;
}

.ctapopup.active {
    transform: translateY(0);
}

.section {
    max-width: 680px;
    margin: 0 auto;
}

.title {
    font-size: 28px;
    font-weight: 700;
    line-height: 1.2;
    font-family: 'Cooper Hewitt';
    text-transform: uppercase;
}

.text {
    font-size: 16px;
    padding-top: 20px;
    line-height: 1.5;
}

.action,
.field {
    padding-top: 20px;
}

.field:first-child {
    padding-top: 0;
}

.infoText {
    font-size: 12px;
    padding-top: 20px;
}

.text a,
.infoText a {
    color: var(--inj-link-color);
    position: relative;
}

.text a::after,
.infoText a::after {
    content: "";
    position: absolute;
    width: 100%;
    height: 1px;
    bottom: 0;
    left: 0;
    background: var(--inj-link-color);
}

.text a:hover::after,
.infoText a:hover::after {
    animation: border-animation .8s;
}

.textWrap,
.imageWrap {
    position: relative;
}

.imageWrap {
    width: 215px;
    max-height: 250px;
}

.withImage {
    display: flex;
    gap: 30px;
}

.withImage .textWrap {
    width: calc(100% - 215px);
}

.narrow .withOutImage {
    max-width: 480px;
    margin: 0 auto;
}

.narrow.form {
    max-width: 920px;
    display: flex;
    gap: 30px;
}

.narrow.form .formWrap {
    width: 50%;
    padding-top: 0;
}

.narrow.form .textWrap {
    width: 50%;
}

.closeActions {
    display: flex;
    align-items: center;
    gap: 15px;
    position: absolute;
    right: 0;
    top: -44px;
    color: var(--inj-close-color);
    padding: 10px;
    background-color: var(--inj-back);
    border-top-left-radius: 10px;
    transition: all 0.4s;
}

.closeActions.hide {
    opacity: 0;
    visibility: hidden;
}

.close {
    font-weight: 700;
    display: flex;
    align-items: center;
}

.close:hover {
    color: var(--inj-close-hover);
}

.close .icon {
    width: 24px;
    height: 24px;
    background-color: var(--inj-close-color);
    border-radius: 50%;
    overflow: hidden;
    margin-left: 10px;
    color: var(--inj-back);
    position: relative;
    transition: all 0.4s;
}

.close:hover .icon {
    background-color: var(--inj-close-hover);
}

.close .icon>div,
.close .icon>svg {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
}

.formWrap {
    padding-top: 30px;
}

.field .checkbox {
    display: flex;
    font-size: 14px;
}

.field .checkbox:hover {
    color: inherit;
}

.field input+p {
    background-color: transparent;
}

.field a {
    color: var(--inj-link-color);
}

.field .checkbox>div {
    min-width: 24px;
}

.field .input {
    overflow: hidden;
}

.submit {
    min-width: 215px;
}

@keyframes border-animation {
    0% {
        width: 0
    }

    20% {
        width: 0
    }

    to {
        width: 100%
    }
}

@media(max-width: 1600px) {
    .ctapopup > .wrap {
        padding-left: 100px;
        padding-right: 100px;
    }

    .title {
        font-size: 25px;
    }
}

@media(max-width: 1024px) {
    .ctapopup > .wrap {
        padding-left: 80px;
        padding-right: 80px;
        max-height: 400px;
        overflow-y: auto;
    }

    .title {
        font-size: 23px;
    }

    .narrow.form {
        display: block;
    }

    .narrow.form .textWrap {
        width: 100%;
    }

    .narrow.form .formWrap {
        width: 100%;
        padding-top: 30px;
    }
}

@media(max-width: 740px) {
    .ctapopup > .wrap {
        padding-left: 40px;
        padding-right: 40px;
        padding-bottom: 40px;
    }

    .title {
        font-size: 20px;
    }

    .imageWrap {
        display: none;
    }

    .withImage .textWrap {
        width: 100%;
    }

    .withImage {
        display: block;
    }

    .text {
        font-size: 15px;
    }
}