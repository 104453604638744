.cookiePopup {
    position: relative;
    min-height: 100vh;
}

.cookiePopup .wrapper {
    position: absolute;
    top: 50%;
    left: 50%;
    color: var(--layout-text);
    background-color: var(--wireframe-black);
    padding: 52px;
    transform: translate(-50%, -50%);
    margin: -12px 0;
}
 
.cookiePopup.cookie-popup {
    text-align: center;
}

.cookiePopup.cookie-popup .wrapper {
    max-width: 400px;
}

.cookiePopup.manage-preference .wrapper {
    max-width: 780px;
    padding: 32px;
}

.cookiePopup .wrapper>* {
    padding: 12px 0;
}

.cookiePopup .title {
    font-family: "Cooper Hewitt";
    font-size: 28px;
    line-height: 1.2;
    text-transform: uppercase;
}

.cookiePopup .subTitle {
    font-size: 19px;
    list-style: 1.2;
    font-weight: 700;
}

.cookiePopup .content {
    margin: -8px 0;
}

.cookiePopup .content>* {
    padding: 8px 0;
}

.cookiePopup .text {
    font-size: 16px;
    line-height: 1.5;
    color: var(--grey);
}

.cookiePopup.manage-preference .text {
    font-size: 14px;
    line-height: 1.2;
}

.cookiePopup .link {
    position: relative;
    color: var(--button-bg);
}

.cookiePopup .link::before {
    content: "";
    background: var(--button-bg);
    height: 1px;
    width: 100%;
    position: absolute;
    bottom: 2px;
}

.cookiePopup .link:hover::before {
    animation: border-animation .8s;
}

.cookiePopup .buttons>* {
    display: inline-block;
    width: 100%;
    line-height: 1.5;
    font-weight: 700;
    padding: 23px 32px;
}

.cookiePopup.manage-preference .buttons>* {
    width: fit-content;
}

.cookiePopup .buttons .manage {
    border-color: #0000;
    background-color: #0000;
    color: var(--grey);
}

.cookiePopup .buttons .manage:hover {
    color: var(--button-bg);
}

/* Accords style begin */
.dropdowns .items {
    position: relative;
}

.dropdowns .items .item {
    position: relative;
    list-style: none;
    overflow: hidden;
    padding: 8px 16px;
}

.dropdowns .item:not(.toggled) {
    border-bottom: 1px solid var(--border);
}

.dropdowns .items .head {
    position: relative;
    display: flex;
    align-items: center;
    width: 100%;
    text-align: start;
    font-size: 14px;
    line-height: 1.2;
    padding: 10px 0;
}

.dropdowns .items .head label > div{
    margin: 0;
}

.dropdowns .items .head .button{
    text-align: start;
    padding-left: 16px;
    padding-right: 24px;
}

.dropdowns .items .head>* {
    display: inline-block;
    vertical-align: middle;
}

.dropdowns .arrow {
    position: absolute;
    top: 50%;
    right: 0;
    transform: translateY(-50%);
}

.dropdowns .arrow svg {
    rotate: -90deg;
    width: 14px;
    height: 14px;
    transition: all .8s;
}

.dropdowns .item.toggled .arrow svg {
    rotate: -270deg;
}

.dropdowns .item .desc {
    font-size: 12px;
    line-height: 1.17;
    height: 0;
    opacity: 0;
    transition: opacity .6s, height .8s;
    margin: 0 40px;
}

/* Accords style end */

@media (max-width: 1100px) {
    .cookiePopup .wrapper {
        padding: 48px;
    }
}

@media (max-width: 1024px) {
    .cookiePopup .wrapper {
        padding: 44px;
    }
    .cookiePopup.manage-preference .buttons > *{
        width: 100%;
    }
}

@media (max-width: 850px) {
    .cookiePopup .wrapper {
        max-width: 100%;
        width: calc(75% - 80px);
        padding: 38px;
    }
}

@media (max-width: 800px) {
    .cookiePopup.manage-preference .wrapper,
    .cookiePopup .wrapper {
        padding: 28px;
    }
    .dropdowns .items .head .button{
        padding-left: 14px;
        padding-right: 20px;
    }
}

@media (max-width: 650px) {
    .cookiePopup .wrapper {
        width: calc(85% - 60px);
        padding: 22px;
    }
}

@media (max-width: 550px) {
    .cookiePopup .wrapper {
        padding: 18px;
        width: calc(100% - 60px);
    }
}

@keyframes border-animation {
    0% {
        width: 0
    }

    20% {
        width: 0
    }

    to {
        width: 100%
    }
}