@font-face {
    font-family: 'Mulish';
    src: url('../fonts/Mulish/Mulish-BoldItalic.eot');
    src: local('Mulish Bold Italic'), local('Mulish-BoldItalic'),
        url('../fonts/Mulish/Mulish-BoldItalic.eot?#iefix') format('embedded-opentype'),
        url('../fonts/Mulish/Mulish-BoldItalic.woff2') format('woff2'),
        url('../fonts/Mulish/Mulish-BoldItalic.woff') format('woff'),
        url('../fonts/Mulish/Mulish-BoldItalic.ttf') format('truetype'),
        url('../fonts/Mulish/Mulish-BoldItalic.svg#Mulish-BoldItalic') format('svg');
    font-weight: bold;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'Mulish';
    src: url('../fonts/Mulish/Mulish-Italic.eot');
    src: local('Mulish Italic'), local('Mulish-Italic'),
        url('../fonts/Mulish/Mulish-Italic.eot?#iefix') format('embedded-opentype'),
        url('../fonts/Mulish/Mulish-Italic.woff2') format('woff2'),
        url('../fonts/Mulish/Mulish-Italic.woff') format('woff'),
        url('../fonts/Mulish/Mulish-Italic.ttf') format('truetype'),
        url('../fonts/Mulish/Mulish-Italic.svg#Mulish-Italic') format('svg');
    font-weight: normal;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'Mulish';
    src: url('../fonts/Mulish/Mulish-ExtraLight.eot');
    src: local('Mulish ExtraLight'), local('Mulish-ExtraLight'),
        url('../fonts/Mulish/Mulish-ExtraLight.eot?#iefix') format('embedded-opentype'),
        url('../fonts/Mulish/Mulish-ExtraLight.woff2') format('woff2'),
        url('../fonts/Mulish/Mulish-ExtraLight.woff') format('woff'),
        url('../fonts/Mulish/Mulish-ExtraLight.ttf') format('truetype'),
        url('../fonts/Mulish/Mulish-ExtraLight.svg#Mulish-ExtraLight') format('svg');
    font-weight: 200;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Mulish';
    src: url('../fonts/Mulish/Mulish-BlackItalic.eot');
    src: local('Mulish Black Italic'), local('Mulish-BlackItalic'),
        url('../fonts/Mulish/Mulish-BlackItalic.eot?#iefix') format('embedded-opentype'),
        url('../fonts/Mulish/Mulish-BlackItalic.woff2') format('woff2'),
        url('../fonts/Mulish/Mulish-BlackItalic.woff') format('woff'),
        url('../fonts/Mulish/Mulish-BlackItalic.ttf') format('truetype'),
        url('../fonts/Mulish/Mulish-BlackItalic.svg#Mulish-BlackItalic') format('svg');
    font-weight: 900;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'Mulish';
    src: url('../fonts/Mulish/Mulish-ExtraLightItalic.eot');
    src: local('Mulish ExtraLight Italic'), local('Mulish-ExtraLightItalic'),
        url('../fonts/Mulish/Mulish-ExtraLightItalic.eot?#iefix') format('embedded-opentype'),
        url('../fonts/Mulish/Mulish-ExtraLightItalic.woff2') format('woff2'),
        url('../fonts/Mulish/Mulish-ExtraLightItalic.woff') format('woff'),
        url('../fonts/Mulish/Mulish-ExtraLightItalic.ttf') format('truetype'),
        url('../fonts/Mulish/Mulish-ExtraLightItalic.svg#Mulish-ExtraLightItalic') format('svg');
    font-weight: 200;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'Mulish';
    src: url('../fonts/Mulish/Mulish-ExtraBoldItalic.eot');
    src: local('Mulish ExtraBold Italic'), local('Mulish-ExtraBoldItalic'),
        url('../fonts/Mulish/Mulish-ExtraBoldItalic.eot?#iefix') format('embedded-opentype'),
        url('../fonts/Mulish/Mulish-ExtraBoldItalic.woff2') format('woff2'),
        url('../fonts/Mulish/Mulish-ExtraBoldItalic.woff') format('woff'),
        url('../fonts/Mulish/Mulish-ExtraBoldItalic.ttf') format('truetype'),
        url('../fonts/Mulish/Mulish-ExtraBoldItalic.svg#Mulish-ExtraBoldItalic') format('svg');
    font-weight: bold;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'Mulish';
    src: url('../fonts/Mulish/Mulish-Black.eot');
    src: local('Mulish Black'), local('Mulish-Black'),
        url('../fonts/Mulish/Mulish-Black.eot?#iefix') format('embedded-opentype'),
        url('../fonts/Mulish/Mulish-Black.woff2') format('woff2'),
        url('../fonts/Mulish/Mulish-Black.woff') format('woff'),
        url('../fonts/Mulish/Mulish-Black.ttf') format('truetype'),
        url('../fonts/Mulish/Mulish-Black.svg#Mulish-Black') format('svg');
    font-weight: 900;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Mulish';
    src: url('../fonts/Mulish/Mulish-ExtraBold.eot');
    src: local('Mulish ExtraBold'), local('Mulish-ExtraBold'),
        url('../fonts/Mulish/Mulish-ExtraBold.eot?#iefix') format('embedded-opentype'),
        url('../fonts/Mulish/Mulish-ExtraBold.woff2') format('woff2'),
        url('../fonts/Mulish/Mulish-ExtraBold.woff') format('woff'),
        url('../fonts/Mulish/Mulish-ExtraBold.ttf') format('truetype'),
        url('../fonts/Mulish/Mulish-ExtraBold.svg#Mulish-ExtraBold') format('svg');
    font-weight: bold;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Mulish';
    src: url('../fonts/Mulish/Mulish-Bold.eot');
    src: local('Mulish Bold'), local('Mulish-Bold'),
        url('../fonts/Mulish/Mulish-Bold.eot?#iefix') format('embedded-opentype'),
        url('../fonts/Mulish/Mulish-Bold.woff2') format('woff2'),
        url('../fonts/Mulish/Mulish-Bold.woff') format('woff'),
        url('../fonts/Mulish/Mulish-Bold.ttf') format('truetype'),
        url('../fonts/Mulish/Mulish-Bold.svg#Mulish-Bold') format('svg');
    font-weight: bold;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Mulish';
    src: url('../fonts/Mulish/Mulish-LightItalic.eot');
    src: local('Mulish Light Italic'), local('Mulish-LightItalic'),
        url('../fonts/Mulish/Mulish-LightItalic.eot?#iefix') format('embedded-opentype'),
        url('../fonts/Mulish/Mulish-LightItalic.woff2') format('woff2'),
        url('../fonts/Mulish/Mulish-LightItalic.woff') format('woff'),
        url('../fonts/Mulish/Mulish-LightItalic.ttf') format('truetype'),
        url('../fonts/Mulish/Mulish-LightItalic.svg#Mulish-LightItalic') format('svg');
    font-weight: 300;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'Mulish';
    src: url('../fonts/Mulish/Mulish-Light.eot');
    src: local('Mulish Light'), local('Mulish-Light'),
        url('../fonts/Mulish/Mulish-Light.eot?#iefix') format('embedded-opentype'),
        url('../fonts/Mulish/Mulish-Light.woff2') format('woff2'),
        url('../fonts/Mulish/Mulish-Light.woff') format('woff'),
        url('../fonts/Mulish/Mulish-Light.ttf') format('truetype'),
        url('../fonts/Mulish/Mulish-Light.svg#Mulish-Light') format('svg');
    font-weight: 300;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Mulish';
    src: url('../fonts/Mulish/Mulish-MediumItalic.eot');
    src: local('Mulish Medium Italic'), local('Mulish-MediumItalic'),
        url('../fonts/Mulish/Mulish-MediumItalic.eot?#iefix') format('embedded-opentype'),
        url('../fonts/Mulish/Mulish-MediumItalic.woff2') format('woff2'),
        url('../fonts/Mulish/Mulish-MediumItalic.woff') format('woff'),
        url('../fonts/Mulish/Mulish-MediumItalic.ttf') format('truetype'),
        url('../fonts/Mulish/Mulish-MediumItalic.svg#Mulish-MediumItalic') format('svg');
    font-weight: 500;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'Mulish';
    src: url('../fonts/Mulish/Mulish-Medium.eot');
    src: local('Mulish Medium'), local('Mulish-Medium'),
        url('../fonts/Mulish/Mulish-Medium.eot?#iefix') format('embedded-opentype'),
        url('../fonts/Mulish/Mulish-Medium.woff2') format('woff2'),
        url('../fonts/Mulish/Mulish-Medium.woff') format('woff'),
        url('../fonts/Mulish/Mulish-Medium.ttf') format('truetype'),
        url('../fonts/Mulish/Mulish-Medium.svg#Mulish-Medium') format('svg');
    font-weight: 500;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Mulish';
    src: url('../fonts/Mulish/Mulish-SemiBold.eot');
    src: local('Mulish SemiBold'), local('Mulish-SemiBold'),
        url('../fonts/Mulish/Mulish-SemiBold.eot?#iefix') format('embedded-opentype'),
        url('../fonts/Mulish/Mulish-SemiBold.woff2') format('woff2'),
        url('../fonts/Mulish/Mulish-SemiBold.woff') format('woff'),
        url('../fonts/Mulish/Mulish-SemiBold.ttf') format('truetype'),
        url('../fonts/Mulish/Mulish-SemiBold.svg#Mulish-SemiBold') format('svg');
    font-weight: 600;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Mulish';
    src: url('../fonts/Mulish/Mulish-Regular.eot');
    src: local('Mulish Regular'), local('Mulish-Regular'),
        url('../fonts/Mulish/Mulish-Regular.eot?#iefix') format('embedded-opentype'),
        url('../fonts/Mulish/Mulish-Regular.woff2') format('woff2'),
        url('../fonts/Mulish/Mulish-Regular.woff') format('woff'),
        url('../fonts/Mulish/Mulish-Regular.ttf') format('truetype'),
        url('../fonts/Mulish/Mulish-Regular.svg#Mulish-Regular') format('svg');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Mulish';
    src: url('../fonts/Mulish/Mulish-SemiBoldItalic.eot');
    src: local('Mulish SemiBold Italic'), local('Mulish-SemiBoldItalic'),
        url('../fonts/Mulish/Mulish-SemiBoldItalic.eot?#iefix') format('embedded-opentype'),
        url('../fonts/Mulish/Mulish-SemiBoldItalic.woff2') format('woff2'),
        url('../fonts/Mulish/Mulish-SemiBoldItalic.woff') format('woff'),
        url('../fonts/Mulish/Mulish-SemiBoldItalic.ttf') format('truetype'),
        url('../fonts/Mulish/Mulish-SemiBoldItalic.svg#Mulish-SemiBoldItalic') format('svg');
    font-weight: 600;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'Cooper Hewitt';
    src: url('../fonts/CooperHewitt/CooperHewitt-BoldItalic.eot');
    src: local('CooperHewitt-BoldItalic'),
        url('../fonts/CooperHewitt/CooperHewitt-BoldItalic.eot?#iefix') format('embedded-opentype'),
        url('../fonts/CooperHewitt/CooperHewitt-BoldItalic.woff2') format('woff2'),
        url('../fonts/CooperHewitt/CooperHewitt-BoldItalic.woff') format('woff'),
        url('../fonts/CooperHewitt/CooperHewitt-BoldItalic.ttf') format('truetype'),
        url('../fonts/CooperHewitt/CooperHewitt-BoldItalic.svg#CooperHewitt-BoldItalic') format('svg');
    font-weight: bold;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'Cooper Hewitt Book';
    src: url('../fonts/CooperHewitt/CooperHewitt-BookItalic.eot');
    src: local('CooperHewitt-BookItalic'),
        url('../fonts/CooperHewitt/CooperHewitt-BookItalic.eot?#iefix') format('embedded-opentype'),
        url('../fonts/CooperHewitt/CooperHewitt-BookItalic.woff2') format('woff2'),
        url('../fonts/CooperHewitt/CooperHewitt-BookItalic.woff') format('woff'),
        url('../fonts/CooperHewitt/CooperHewitt-BookItalic.ttf') format('truetype'),
        url('../fonts/CooperHewitt/CooperHewitt-BookItalic.svg#CooperHewitt-BookItalic') format('svg');
    font-weight: normal;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'Cooper Hewitt';
    src: url('../fonts/CooperHewitt/CooperHewitt-Light.eot');
    src: local('CooperHewitt-Light'),
        url('../fonts/CooperHewitt/CooperHewitt-Light.eot?#iefix') format('embedded-opentype'),
        url('../fonts/CooperHewitt/CooperHewitt-Light.woff2') format('woff2'),
        url('../fonts/CooperHewitt/CooperHewitt-Light.woff') format('woff'),
        url('../fonts/CooperHewitt/CooperHewitt-Light.ttf') format('truetype'),
        url('../fonts/CooperHewitt/CooperHewitt-Light.svg#CooperHewitt-Light') format('svg');
    font-weight: 300;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Cooper Hewitt';
    src: url('../fonts/CooperHewitt/CooperHewitt-Heavy.eot');
    src: local('CooperHewitt-Heavy'),
        url('../fonts/CooperHewitt/CooperHewitt-Heavy.eot?#iefix') format('embedded-opentype'),
        url('../fonts/CooperHewitt/CooperHewitt-Heavy.woff2') format('woff2'),
        url('../fonts/CooperHewitt/CooperHewitt-Heavy.woff') format('woff'),
        url('../fonts/CooperHewitt/CooperHewitt-Heavy.ttf') format('truetype'),
        url('../fonts/CooperHewitt/CooperHewitt-Heavy.svg#CooperHewitt-Heavy') format('svg');
    font-weight: 900;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Cooper Hewitt';
    src: url('../fonts/CooperHewitt/CooperHewitt-HeavyItalic.eot');
    src: local('CooperHewitt-HeavyItalic'),
        url('../fonts/CooperHewitt/CooperHewitt-HeavyItalic.eot?#iefix') format('embedded-opentype'),
        url('../fonts/CooperHewitt/CooperHewitt-HeavyItalic.woff2') format('woff2'),
        url('../fonts/CooperHewitt/CooperHewitt-HeavyItalic.woff') format('woff'),
        url('../fonts/CooperHewitt/CooperHewitt-HeavyItalic.ttf') format('truetype'),
        url('../fonts/CooperHewitt/CooperHewitt-HeavyItalic.svg#CooperHewitt-HeavyItalic') format('svg');
    font-weight: 900;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'Cooper Hewitt';
    src: url('../fonts/CooperHewitt/CooperHewitt-Bold.eot');
    src: local('CooperHewitt-Bold'),
        url('../fonts/CooperHewitt/CooperHewitt-Bold.eot?#iefix') format('embedded-opentype'),
        url('../fonts/CooperHewitt/CooperHewitt-Bold.woff2') format('woff2'),
        url('../fonts/CooperHewitt/CooperHewitt-Bold.woff') format('woff'),
        url('../fonts/CooperHewitt/CooperHewitt-Bold.ttf') format('truetype'),
        url('../fonts/CooperHewitt/CooperHewitt-Bold.svg#CooperHewitt-Bold') format('svg');
    font-weight: bold;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Cooper Hewitt Book';
    src: url('../fonts/CooperHewitt/CooperHewitt-Book.eot');
    src: local('CooperHewitt-Book'),
        url('../fonts/CooperHewitt/CooperHewitt-Book.eot?#iefix') format('embedded-opentype'),
        url('../fonts/CooperHewitt/CooperHewitt-Book.woff2') format('woff2'),
        url('../fonts/CooperHewitt/CooperHewitt-Book.woff') format('woff'),
        url('../fonts/CooperHewitt/CooperHewitt-Book.ttf') format('truetype'),
        url('../fonts/CooperHewitt/CooperHewitt-Book.svg#CooperHewitt-Book') format('svg');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Cooper Hewitt';
    src: url('../fonts/CooperHewitt/CooperHewitt-Medium.eot');
    src: local('CooperHewitt-Medium'),
        url('../fonts/CooperHewitt/CooperHewitt-Medium.eot?#iefix') format('embedded-opentype'),
        url('../fonts/CooperHewitt/CooperHewitt-Medium.woff2') format('woff2'),
        url('../fonts/CooperHewitt/CooperHewitt-Medium.woff') format('woff'),
        url('../fonts/CooperHewitt/CooperHewitt-Medium.ttf') format('truetype'),
        url('../fonts/CooperHewitt/CooperHewitt-Medium.svg#CooperHewitt-Medium') format('svg');
    font-weight: 500;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Cooper Hewitt';
    src: url('../fonts/CooperHewitt/CooperHewitt-LightItalic.eot');
    src: local('CooperHewitt-LightItalic'),
        url('../fonts/CooperHewitt/CooperHewitt-LightItalic.eot?#iefix') format('embedded-opentype'),
        url('../fonts/CooperHewitt/CooperHewitt-LightItalic.woff2') format('woff2'),
        url('../fonts/CooperHewitt/CooperHewitt-LightItalic.woff') format('woff'),
        url('../fonts/CooperHewitt/CooperHewitt-LightItalic.ttf') format('truetype'),
        url('../fonts/CooperHewitt/CooperHewitt-LightItalic.svg#CooperHewitt-LightItalic') format('svg');
    font-weight: 300;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'Cooper Hewitt';
    src: url('../fonts/CooperHewitt/CooperHewitt-MediumItalic.eot');
    src: local('CooperHewitt-MediumItalic'),
        url('../fonts/CooperHewitt/CooperHewitt-MediumItalic.eot?#iefix') format('embedded-opentype'),
        url('../fonts/CooperHewitt/CooperHewitt-MediumItalic.woff2') format('woff2'),
        url('../fonts/CooperHewitt/CooperHewitt-MediumItalic.woff') format('woff'),
        url('../fonts/CooperHewitt/CooperHewitt-MediumItalic.ttf') format('truetype'),
        url('../fonts/CooperHewitt/CooperHewitt-MediumItalic.svg#CooperHewitt-MediumItalic') format('svg');
    font-weight: 500;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'Cooper Hewitt';
    src: url('../fonts/CooperHewitt/CooperHewitt-SemiboldItalic.eot');
    src: local('CooperHewitt-SemiboldItalic'),
        url('../fonts/CooperHewitt/CooperHewitt-SemiboldItalic.eot?#iefix') format('embedded-opentype'),
        url('../fonts/CooperHewitt/CooperHewitt-SemiboldItalic.woff2') format('woff2'),
        url('../fonts/CooperHewitt/CooperHewitt-SemiboldItalic.woff') format('woff'),
        url('../fonts/CooperHewitt/CooperHewitt-SemiboldItalic.ttf') format('truetype'),
        url('../fonts/CooperHewitt/CooperHewitt-SemiboldItalic.svg#CooperHewitt-SemiboldItalic') format('svg');
    font-weight: 600;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'Cooper Hewitt';
    src: url('../fonts/CooperHewitt/CooperHewitt-ThinItalic.eot');
    src: local('CooperHewitt-ThinItalic'),
        url('../fonts/CooperHewitt/CooperHewitt-ThinItalic.eot?#iefix') format('embedded-opentype'),
        url('../fonts/CooperHewitt/CooperHewitt-ThinItalic.woff2') format('woff2'),
        url('../fonts/CooperHewitt/CooperHewitt-ThinItalic.woff') format('woff'),
        url('../fonts/CooperHewitt/CooperHewitt-ThinItalic.ttf') format('truetype'),
        url('../fonts/CooperHewitt/CooperHewitt-ThinItalic.svg#CooperHewitt-ThinItalic') format('svg');
    font-weight: 100;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'Cooper Hewitt';
    src: url('../fonts/CooperHewitt/CooperHewitt-Semibold.eot');
    src: local('CooperHewitt-Semibold'),
        url('../fonts/CooperHewitt/CooperHewitt-Semibold.eot?#iefix') format('embedded-opentype'),
        url('../fonts/CooperHewitt/CooperHewitt-Semibold.woff2') format('woff2'),
        url('../fonts/CooperHewitt/CooperHewitt-Semibold.woff') format('woff'),
        url('../fonts/CooperHewitt/CooperHewitt-Semibold.ttf') format('truetype'),
        url('../fonts/CooperHewitt/CooperHewitt-Semibold.svg#CooperHewitt-Semibold') format('svg');
    font-weight: 600;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Cooper Hewitt';
    src: url('../fonts/CooperHewitt/CooperHewitt-Thin.eot');
    src: local('CooperHewitt-Thin'),
        url('../fonts/CooperHewitt/CooperHewitt-Thin.eot?#iefix') format('embedded-opentype'),
        url('../fonts/CooperHewitt/CooperHewitt-Thin.woff2') format('woff2'),
        url('../fonts/CooperHewitt/CooperHewitt-Thin.woff') format('woff'),
        url('../fonts/CooperHewitt/CooperHewitt-Thin.ttf') format('truetype'),
        url('../fonts/CooperHewitt/CooperHewitt-Thin.svg#CooperHewitt-Thin') format('svg');
    font-weight: 100;
    font-style: normal;
    font-display: swap;
}

