.center {
    max-width: 1520px;
    padding: 0 60px;
    margin: auto;
}
@media (max-width: 1024px) {
    .center {
        padding: 0 40px;
        max-width: 880px;
    }
}
@media (max-width: 550px) {
    .center {
        padding: 0 30px;
    }
}
@media (max-width: 420px) {
    .center {
        padding: 0 20px;
    }
}